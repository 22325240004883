import Image from "next/image";
import logo from "../../../public/img/worpel/worpel-bold.svg";

export function Hero() {
  return (
    <div className="mx-auto max-w-lg pt-6 pb-1 text-center md:pt-11 md:pb-4">
      <h1 className="mt-1.5 mb-2 md:mt-0 md:mb-4">
        <div className="mx-auto w-80 max-w-[180px] md:max-w-[250px]">
          <Image
            src={logo}
            alt="Worpel logo"
            layout="intrinsic"
            width={250}
            height={40}
          />
        </div>
      </h1>
      <h2 className="font-light uppercase text-worpel-blue md:text-2xl">
        AFL Player Guessing Game
      </h2>
    </div>
  );
}
