import { Dialog, Transition } from "@headlessui/react";
import { Fragment, useState } from "react";
import logo from "../../../../../public/img/legends/legends-logo.png";
import Image from "next/image";

function LegendsButton(params) {
  const [isOpen, setIsOpen] = useState(false);

  function openModal(e) {
    if ("legends" in localStorage === false) {
      e.preventDefault();
      setIsOpen(true);
    }
  }

  function closeModal() {
    setIsOpen(false);
  }

  return (
    <>
      <a
        href="/legends"
        // onClick={openModal}
        className="px-5 py-2 text-xs font-bold uppercase text-white hover:text-stone-200 md:px-7 md:py-3 md:text-sm"
      >
        Play Legends
      </a>

      <Transition appear show={isOpen} as={Fragment}>
        <Dialog
          as="div"
          className="fixed inset-0 z-10 overflow-y-auto"
          onClose={closeModal}
        >
          <div className="min-h-screen px-2.5 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-50"
              leave="ease-in duration-200"
              leaveFrom="opacity-50"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 bg-black" />
            </Transition.Child>

            {/* This element is to trick the browser into centering the modal contents. */}
            <span
              className="inline-block h-[calc(var(--vh,1vh)*100)] align-middle"
              aria-hidden="true"
            >
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-500 delay-100"
              enterFrom="opacity-0 scale-100 mb-20"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100 mb-20"
              leaveTo="opacity-0 scale-100"
            >
              <div className="inline-block w-full max-w-md transform overflow-hidden rounded-md bg-white py-4 px-2 text-left align-middle shadow-xl transition-all">
                <Dialog.Title
                  as="h3"
                  className="pl-7 text-center text-lg font-bold leading-6 text-worpel-blue"
                >
                  <ModalCloseButton clickCallback={closeModal} />
                </Dialog.Title>
                <ModalBody />
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition>
    </>
  );
}

function ModalBody() {
  return (
    <div className="text-center text-worpel-blue">
      <div className="mt-2 border-solid border-worpel-blue px-1 md:py-2">
        <div className="mx-auto w-80 max-w-[160px] md:max-w-[200px]">
          <Image
            src={logo}
            alt="Worpel Legends logo"
            layout="intrinsic"
            width={536}
            height={211}
          />
        </div>
        <div
          className="inner false mx-auto -mt-7 mb-1 h-[190px] w-60 bg-contain bg-center bg-no-repeat md:h-[220px] md:w-64"
          style={{
            backgroundImage: `url("/img/legends/legends-promo-silhouette.png")`,
          }}
        ></div>
        <div className="mb-5 px-4 md:mb-7 md:text-xl">
          Guess the footy legend in this all-new challenge!
        </div>
        <a
          className="mb-1 inline-block rounded bg-worpel-blue py-3 px-9 text-sm font-bold uppercase text-white md:text-base"
          href="/legends"
        >
          Play Now!
        </a>
      </div>
    </div>
  );
}

function ModalCloseButton({ clickCallback }) {
  return (
    <button
      type="button"
      className="float-right -mt-2 inline-flex justify-center px-2 py-2 text-2xl font-medium leading-none"
      onClick={clickCallback}
    >
      ×
    </button>
  );
}

export default LegendsButton;
