import { HelpListItem } from "../../components/modals/HowToPlayButton";

export function HowToPlayBody() {
  return (
    <div className="mt-2 text-md">
      <ul className="my-4 list-disc pl-4">
        <HelpListItem afterText="You get eight guesses, try any current AFL player" />
        <HelpListItem
          bgColor="bg-green-500"
          textColor="text-white"
          highlightText="Green in any column"
          afterText="indicates a match!"
        />
        <HelpListItem
          bgColor="bg-yellow-200"
          highlightText={
            <>
              Yellow in the <strong>TEAM</strong> column
            </>
          }
          afterText="indicates the mystery player's team shares one or more colours"
        />
        <HelpListItem
          bgColor="bg-yellow-200"
          highlightText={
            <>
              Yellow in the <strong>STATE</strong> column
            </>
          }
          afterText="indicates the mystery player plays in a bordering state"
        />
        <HelpListItem
          bgColor="bg-yellow-200"
          highlightText={
            <>
              Yellow in the <strong>POS</strong> column
            </>
          }
          afterText="indicates a partial match to the mystery player's position"
        />
        <HelpListItem
          bgColor="bg-yellow-200"
          highlightText="Yellow in any other column"
          afterText="indicates this attribute is within 3 (cm, years, numbers) of the mystery player"
        />
        <HelpListItem afterText="A new mystery player every day!" />
      </ul>
    </div>
  );
}
